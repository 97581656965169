import * as React from 'react';
import '../styles/App.less';
import {Helmet} from 'react-helmet';
import {PageProps} from 'gatsby';
import {setUTMCookie} from '../utils/CookieUtil';
import Auth0 from '../components/Login/Auth0';
// markup
const IndexPage = (props: PageProps) => {
  setUTMCookie(props);
  return (
    <>
      <Helmet htmlAttributes={{lang: 'en'}}>
        <meta charSet='utf-8' />
        <meta
          name='description'
          content={
            'Cribl Cloud is the leading data observability platform in the world. Try Stream in the cloud today!'
          }
        />
        <title>Cribl.Cloud </title>
        <link rel='canonical' href='https://cribl.cloud' />
      </Helmet>
      <main key={'login'} className={'main-holder-auth0'}>
        <Auth0 />
      </main>
    </>
  );
};

export default IndexPage;
