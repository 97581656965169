import {PageProps} from 'gatsby';

const utms = ['utm_source', 'utm_medium', 'utm_campaign'];

export const setUTMCookie = (pageProps: PageProps) => {
  const params = new URLSearchParams(pageProps?.location?.search);
  utms.forEach((utm) => {
    const utmValue = params.get(utm);
    if (utmValue) {
      setCookie(utm, utmValue);
    }
  });
};

export const setCookie = (cookieName: string, value: string, days = 360, path = '/') => {
  if (typeof window === 'undefined') return '';
  const domain = `domain=${window?.location?.hostname?.split('.')?.slice(-2)?.join('.')};`;
  const expires = new Date(Date.now() + days * 86400 * 1000).toUTCString();
  document.cookie = `${cookieName}=${value};expires=${expires};path=${path};${domain}`;
};

export const getCookie = (cookieName: string): string => {
  if (typeof window === 'undefined') return '';
  const match = window.document?.cookie.match(new RegExp('(^| )' + cookieName + '=([^;]+)'));
  return match?.[2] || '';
};
